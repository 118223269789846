import React, { useState } from 'react';
import styled from 'styled-components';
import image1 from '../partyImages/constitution.png';
import image2 from '../partyImages/democrat.png';
import image3 from '../partyImages/green.png';
import image4 from '../partyImages/justiceforall.png';
import image5 from '../partyImages/libertarian.png';
import image6 from '../partyImages/republican.png';
import MintButton from './MintButton';

const AppWrapper = styled.div`
  background-color: ${(props) => props.backgroundColor};
  transition: background-color 0.3s ease-in-out;

max-height: min-content;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding:18px;
  border-radius: 88px;

  @media(max-width:768px){
    // height: 70vh;
    margin-top:20px;
    }
`;

const Circle = styled.div`
  position: relative;
  width: 90vw;
  height: 90vw;
  max-width: 500px;
  max-height: 500px;
  border-radius: 50%;
  background-color: #f0f0f0;
  overflow: hidden;
  margin: 20px 0;
  display: flex;
  align-items: center;
  justify-content: center;

  // @media(max-width:768px){
  //   width:80vw;
  //   height: 80vw;
  // }
`;

const CenterText = styled.div`
  font-family: "Afacad Flux", sans-serif;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 2.5vw;
  font-weight: 300;
  z-index: 10;
  text-align: center;
  text-transform: uppercase;

  @media(max-width:768px){
    font-size: 5vw;
  }
`;

const SectorButton = styled.div`
  position: absolute;
  width: 60%;
  height: 93%;
  border: 2px solid white;
  clip-path: polygon(50% 50%, 100% 0, 100% 100%);
  transform-origin: center;
  transform: rotate(${(props) => props.rotation}) translate(45%, 0);
  background-image: url(${(props) => props.backgroundImage});
  background-size: cover;
  background-position: center;
  transition: transform 0.3s ease-in-out, background-size 0.3s ease-in-out;

  &:hover {
    background-size: 108%;
    transform: rotate(${(props) => props.rotation}) translate(45%, 0) scale(1.05);
  }
`;

const Header = styled.h1`
  font-family: "Roboto", sans-serif;
  font-size: 2rem;
  margin-bottom: 10px;
  text-align: center;
  color: ${(props) => props.color};
  background-color: white;
  padding: 10px 20px;
  border-radius: 10px;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);

  @media(max-width:768px){
    font-size:1.65rem;
  }
`;

const rotations = [
  { rotation: '0deg', image: image1, color: '#8b4513', name: 'Constitution' },
  { rotation: '60deg', image: image2, color: '#005eb8', name: 'Democratic' },
  { rotation: '120deg', image: image3, color: '#228b22', name: 'Green' },
  { rotation: '180deg', image: image4, color: '#800080', name: 'Justice For All' },
  { rotation: '240deg', image: image5, color: '#ffd700', name: 'Libertarian' },
  { rotation: '300deg', image: image6, color: '#d72638', name: 'Republican' },
];

const CircleMenu = ({ onColorSelect }) => {
  const [backgroundColor, setBackgroundColor] = useState('#f0f0f0');
  const [selectedParty, setSelectedParty] = useState('Select Your Favorite Party from the Ballot Circle');
  const [headerColor, setHeaderColor] = useState('black');
  const [selectedColor, setSelectedColor] = useState('');

  const handleSectorClick = (color, name) => {
    setBackgroundColor(color);
    setSelectedParty(name);
    setSelectedColor(name);
    setHeaderColor('black');
    onColorSelect(name);
  };

  return (
    <AppWrapper backgroundColor={backgroundColor}>
      <Header color={headerColor}>{selectedParty}</Header>
      <Circle>
        <CenterText>#MintAVote</CenterText>
        {rotations.map((sector, index) => (
          <SectorButton
            key={index}
            rotation={sector.rotation}
            backgroundImage={sector.image}
            onClick={() => handleSectorClick(sector.color, sector.name)}
          />
        ))}
      </Circle>
    </AppWrapper>
  );
};

export default CircleMenu;
