import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import CircleMenu from './CircleMenu';
import { doc, getDoc } from 'firebase/firestore';
import { db } from '../Firebase';
import MintButton from './MintButton';
import Header from './Header';
import HexagonMenu from './HexagonMenu';

const PageContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: 100vh;
  background-color: #f7f7f7;
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

// const StatisticsSection = styled.div`
//   margin: 20px auto;
//   padding: 20px;
//   background-color: #ffffff;
//   border-radius: 10px;
//   box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
//   max-width: 1200px;
//   width: 60%;
//   font-family: "Afacad Flux", sans-serif;
// `;

// const StatisticsTitle = styled.h3`
//   font-size: 1.5rem;
//   color: #333;
//   margin-bottom: 20px;
//   text-align: center;
// `;

// const StatGrid = styled.div`
//   display: grid;
//   grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
//   gap: 20px;

//   @media (min-width: 768px) {
//     grid-template-columns: repeat(3, 1fr);
//   }
// `;
// const StatCard = styled.div`
//   border-radius: 8px;
//   border: 3px solid ${props => props.color};
//   padding: 8px;
//   text-align: center;
//   transition: transform 0.2s ease-in-out, background-color 0.2s ease-in-out;

//   &:hover {
//     transform: translateY(-5px);
//     background-color: ${props => props.color};

//     // This is just the card hover effect, no text color change here
//   }
// `;

// const PartyName = styled.p`
//   font-size: 1rem;
//   font-weight: bold;
//   margin-bottom: 2.5px;
//   color: ${props => props.defaultColor}; // Default color as a prop
//   transition: color 0.2s ease-in-out;

//   ${StatCard}:hover & {
//     color: ${props => props.hoverColor}; // Color change on hover
//   }
// `;

// const PartyCount = styled.p`
//   font-size: 2.5rem;
//   font-weight: 400;
//   color: ${props => props.defaultColor}; // Default color as a prop
//   transition: color 0.2s ease-in-out;

//   ${StatCard}:hover & {
//     color: ${props => props.hoverColor}; // Color change on hover
//   }
// `;



// const MostVotesSection = styled.div`
//   margin-top: 20px;
//   text-align: center;
//   font-size: 1.2rem;
//   font-weight: bold;
//   color: #333;
// `;
const StatisticsSection = styled.div`
  margin: 40px auto;
  padding: 40px;
  background-color: #f5f5f5;
  border-radius: 20px;
  max-width: 900px;
  text-align: center;
`;

const StatisticsTitle = styled.h2`
  font-size: 2rem;
  font-family: "Roboto", sans-serif;
  font-weight: bold;
  margin-bottom: 20px;
  color: #333;
`;

const StatGrid = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  margin-bottom: 30px;
`;

const StatCard = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 15px;
  border-radius: 12px;
  // border: 4px solid ${props => props.color};
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease-in-out;

  &:hover {
    transform: scale(1.05);
  }
`;

const PartyDetails = styled.div`
  flex: 0 0 30%; /* Fixed width for the party name */
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;

const PartyName = styled.h3`
  font-size: 1.5rem;
  color: black;
  text-align:left;
  font-weight: bold;
`;

const PartyCount = styled.div`
  font-size: 2rem;
  font-weight: bold;
  color: black;
  padding-bottom:20px;
`;

const ProgressBarWrapper = styled.div`
  flex: 1; /* Takes the rest of the available space */
  padding-left: 20px;
`;

const ProgressBarBackground = styled.div`
  height: 8px;
  width: 100%;
  background-color: #e0e0e0;
  border-radius: 4px;
  overflow: hidden;
  position: relative; /* To ensure absolute positioning works inside */
`;

const ProgressBarFill = styled.div`
  height: 100%;
  width: ${props => props.percentage}%;
  background-color: ${props => props.color};
  position: absolute;
`;

const CallToAction = styled.div`
  margin-top: 50px;
  padding: 20px;
  background-color: ${props => props.bgColor};

  color: ${props => props.txColor};
  border-radius: 10px;
  font-size: 1.3rem;
  font-weight: bold;
  cursor: pointer;
`;

const getContrastColor = (hexColor) => {
    const r = parseInt(hexColor.slice(1, 3), 16);
    const g = parseInt(hexColor.slice(3, 5), 16);
    const b = parseInt(hexColor.slice(5, 7), 16);
    const luminance = (0.299 * r + 0.587 * g + 0.114 * b) / 255;
    return luminance > 0.5 ? '#000000' : '#FFFFFF';
};

const partyColors = {
    Republican: '#d72638',
    Democratic: '#005eb8',
    Constitution: '#8b4513',
    Libertarian: '#ffd700',
    Green: '#228b22',
    'Justice For All': '#800080'
};


const New = () => {
    const [selectedColor, setSelectedColor] = useState('');
    const [isMinting, setIsMinting] = useState(false);
    const [stats, setStats] = useState({
        Republican: 0,
        Democratic: 0,
        Constitution: 0,
        Libertarian: 0,
        Green: 0,
        'Justice For All': 0
    });
    const [mostVotes, setMostVotes] = useState({ party: '', count: 0 });

    useEffect(() => {
        const fetchStats = async () => {
            try {
                const parties = ['Republican', 'Democratic', 'Constitution', 'Libertarian', 'Green', 'Justice For All'];
                const newStats = {};
                for (const party of parties) {
                    const docRef = doc(db, 'stats', party);
                    const docSnap = await getDoc(docRef);
                    if (docSnap.exists()) {
                        newStats[party] = docSnap.data().count;
                    } else {
                        newStats[party] = 0;
                    }
                }
                setStats(newStats);
                updateMostVotes(newStats);
            } catch (error) {
                console.error('Error fetching stats from Firestore:', error);
            }
        };

        fetchStats();
    }, []);

    const updateMostVotes = (currentStats) => {
        const maxVotes = Math.max(...Object.values(currentStats));
        const partyWithMostVotes = Object.keys(currentStats).find(party => currentStats[party] === maxVotes);
        setMostVotes({ party: partyWithMostVotes, count: maxVotes });
    };

    const handleColorSelect = (color) => {
        setSelectedColor(color);
    };

    const handleMintSuccess = () => {
        setStats(prevStats => {
            const newStats = {
                ...prevStats,
                [selectedColor]: prevStats[selectedColor] + 1
            };
            updateMostVotes(newStats);
            return newStats;
        });
    };

    return (
        <PageContainer>
            <Header />
            <Content>
                <CircleMenu onColorSelect={handleColorSelect} />

                {/* <HexagonMenu onColorSelect={handleColorSelect} /> */}
                {isMinting ? (
                    <p>Minting in progress...</p>
                ) : (
                    <MintButton
                        selectedColor={selectedColor}
                        setIsMinting={setIsMinting}
                        onMintSuccess={handleMintSuccess}
                    />
                )}
            </Content>
            {/* <StatisticsSection>
                <StatisticsTitle>Minting Stats</StatisticsTitle>
                <StatGrid>
                    {Object.entries(stats).map(([party, count]) => {
                        const bgColor = partyColors[party];
                        const textColor = getContrastColor(bgColor);
                        return (
                            //   <StatCard key={party} color={bgColor}>
                            //     <PartyName textColor={textColor}>{party}</PartyName>
                            //     <PartyCount textColor={textColor}>{count}</PartyCount>
                            //   </StatCard>
                            <StatCard color={bgColor} textColor={textColor}>
                                <PartyName defaultColor="black" hoverColor={textColor}>{party}</PartyName>
                                <PartyCount defaultColor="black" hoverColor={textColor}>{count}</PartyCount>
                            </StatCard>
                        );
                    })}
                </StatGrid>
                <MostVotesSection>
                    Party with Most Votes: {mostVotes.party} ({mostVotes.count} votes)
                </MostVotesSection>
            </StatisticsSection>  */}
            <StatisticsSection>
  <StatisticsTitle>Minting Stats</StatisticsTitle>
  <StatGrid>
    {stats && Object.entries(stats).map(([party, count]) => {
      const bgColor = partyColors[party] || "#ccc"; // Default color fallback
      const textColor = getContrastColor(bgColor);
      const mostVotesCount = mostVotes?.count || 1; // Prevent division by 0
      const percentage = (count / mostVotesCount) * 100;

      return (
        <StatCard key={party} color={bgColor}>
          <PartyDetails>
            <PartyName textColor={textColor}>{party}</PartyName>
            <PartyCount textColor={textColor}>{count}</PartyCount>
          </PartyDetails>
          <ProgressBarWrapper>
            <ProgressBarBackground>
              <ProgressBarFill color={bgColor} percentage={percentage} />
            </ProgressBarBackground>
          </ProgressBarWrapper>
        </StatCard>
      );
    })}
  </StatGrid>
  {mostVotes && mostVotes.party && (
    <CallToAction
      bgColor={partyColors[mostVotes.party] || "#ccc"}
      txColor={getContrastColor(partyColors[mostVotes.party] || "#ccc")}
    >
      Leading Party: {mostVotes.party} with {mostVotes.count} votes
    </CallToAction>
  )}
</StatisticsSection>
{/* <CallToAction bgColor={partyColors[mostVotes.party]} txColor={getContrastColor(partyColors[mostVotes.party])}>Leading Party: {mostVotes.party} with {mostVotes.count} votes</CallToAction> */}
        </PageContainer>
    );
};

export default New;